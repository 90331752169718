import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/DefaultLayout.tsx";
import PageLink from "../../components/PageLink.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`„Bahá'u'lláh hat den Kreis der Einigkeit geschlagen. Er hat einen Plan geschaffen, um alle Völker zu vereinen und sie alle unter dem schützenden Zelt der allumfassenden Einheit zu versammeln. Dies ist das Werk der göttlichen Freigebigkeit, und wir alle müssen uns mit Herz und Seele mühen, bis wir die Einheit tatsächlich in unserer Mitte haben, und in dem Maß, in dem wir arbeiten, werden wir Kraft empfangen.”`}</p>
      <cite>—‘Abdu’l-Bahá</cite>
    </blockquote>
    <p>{`Die Bahá’í-Gemeinde Mannheim bietet regelmäßige Veranstaltungen und Aktivitäten wie z.B. Kinderklassen und Andachten an, die dazu beitragen sollen, eine friedlichere und geistige Welt aufzubauen. Sie sind herzlich eingeladen, daran teilzunehmen und unser Vorhaben zu unterstützen.`}</p>
    <PageLink label="Andachten" to="/eine-aktive-gemeinde/andachten/" mdxType="PageLink" />
    <PageLink label="Kinderklassen" to="/eine-aktive-gemeinde/kinderklassen/" mdxType="PageLink" />
    <PageLink label="Juniorjugendprogramm" to="/eine-aktive-gemeinde/juniorjugendprogramm/" mdxType="PageLink" />
    <PageLink label="Studienkreise" to="/eine-aktive-gemeinde/studienkreise/" mdxType="PageLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      